let configs = {
  baseURL: '',
  shopDomain: '',
  originDomain: '',
  environment: '',
  title: 'PJF Wine',
  appId: 'app.pjfwine.com',
  baseURLInfo: {
    local: 'http://localhost:3000/api',
    development: 'http://pjfuat.cocoho.com/api',
    production: 'https://pjfwines.com/api'
  },
  shopDomainInfo: {
    local: 'localhost:3003',
    development: 'localhost:3003',
    production: 'pjfwines.com'
  },
  originDomainInfo: {
    local: 'localhost:3003',
    development: 'localhost:3003',
    production: 'pjfwines.com'
  },
  axiosTimeout: 300000,
  tokenSession: 'pjf-wine-app',
  // tokenExpiration: 86400000,
  tokenExpiration: 2592000000,
  tokenExpirationRememberMe: 2592000000,
  encryptionSecret: 'zHs4s2kadhs1$23@Sasd1a9kjaj2xj1aki11asdjd',
  externalPageLinks: {
    termsAndConditions: '/terms',
    privacyPolicy: '/privacy',
    orderAndShipping: '/order',
    returnPolicy: '/return',
    contact: '/contact'
  },
  socialLinks: {
    facebook: '',
    instagram: '',
    youtube: ''
  },
  contactInfo: {
    telephone: ['(852) 2233 2787'],
    mobile: [],
    email: '',
    address: '13103N, ATL Logistics Centre B, 3 Kwai Chung Container Terminals, New Territories, Hong Kong',
    map: ''
  }
}

configs.environment = 'production'
configs.baseURL = configs.baseURLInfo[configs.environment]
configs.shopDomain = configs.shopDomainInfo[configs.environment]
configs.originDomain = configs.originDomainInfo[configs.environment]

for (let key in configs.externalPageLinks) {
  configs.externalPageLinks[key] = configs.baseURL.replace('/api', '/page') + configs.externalPageLinks[key]
}

export default configs
