import { helpers } from '@/utils/helpers.js'
import { post, get } from '@/utils/axios'
import { configs } from '@/utils/configs'

import { useStore } from '@/store'
const store = useStore()

const saveOpendId = async (store, code) => {
  let res = await get('/order/openid', { code })
  await store.dispatch('page/setData', { OpenId: res.OpenId })
}

const processPayment = async (PaymentMethod, OrderKey) => {
  let isWechat = helpers.isInsideWeixin()
  let isMobile = helpers.isMobile()
  let isMini = helpers.isMiniProgram()

  if (isMini && PaymentMethod == 'WeChatPay_Acc') {
    //pay now
    const { OpenId } = store.getters['page/getData']
    let res = await post('/order/wechatpay', { OrderKey, OpenId })
    if (!res) {
      window.location.replace('/order/view/' + OrderKey)
      return
    }

    try {
      let { Wechatpay } = res
      var encodedString = encodeURIComponent(Wechatpay.response.payment_bankInfo)
      wx.miniProgram.navigateTo({
        url: '/pages/pjfwines/payment?order=' + OrderKey + '&obj=' + encodedString
      })
    } catch (err) {
      console.log(err)
      window.location.replace('/order/view/' + OrderKey)
    }
  } else {
    let redirectPayment = `${configs.baseURL}/webhook/payment/pay/${PaymentMethod}/${OrderKey}?p=1`
    if (!isWechat && !isMobile) redirectPayment += '&isDesktop=1'
    if (!isWechat && isMobile) redirectPayment += '&isMobile=1'
    window.location.href = redirectPayment
  }
}

export { processPayment, saveOpendId }
