import routes from '@/routes'

import { configs, extendedConfigs } from '@/utils/configs'
import { helpers } from '@/utils/helpers'
import { redirectDomain, storeToken, redirectToHome, redirectToSplash } from '@/logics/auth.js'

import { useStore } from '@/store'
const store = useStore()

let shopDomain = configs.shopDomain || 'localhost:8082'
let originDomain = configs.originDomain || 'localhost:8081'

const checkMeta = async ({ to, from, resolve, reject, router }) => {
  await storeToken(shopDomain, to?.query?.token)
  document.getElementById('app').classList.remove('splash-render-page') //remove splash class

  //bypass
  // if(to.route.name != "orderViewPage" && to.route.name != "cartPage"){
  //   to.route.meta.hideMainToolbar = false;
  // }

  //if the value is not the array set the toolbar config to false..else set to true
  if (!(extendedConfigs?.pagesToHideToolBar.indexOf(to.route.name) > -1)) {
    to.route.meta.hideMainToolbar = false
  }

  if (!(extendedConfigs?.pageToHidePopupBanner.indexOf(to.route.name) > -1)) {
    to.route.meta.hidePopupBanner = false
  } else {
    to.route.meta.hidePopupBanner = true
  }

  const IsNewUser = store.getters['user/getData']?.IsNewUser
  if (extendedConfigs?.pageToShowRegisterBanner.indexOf(to.route.name) > -1 && IsNewUser > 0) {
    to.route.meta.showRegisterBanner = true
  } else {
    to.route.meta.showRegisterBanner = false
  }

  store.dispatch('page/setData', { isSplash: false, activeRoute: to.route })

  const userToken = store.getters['user/getToken']
  const userProductCode = store.getters['user/getData']?.ProductCode
  const userKolCode = store.getters['user/getData']?.KolCode
  const userPostKey = store.getters['user/getData']?.PostKey
  const surveyKey = store.getters['user/getData']?.SurveyKey

  if (!helpers.canAddToCart() && to?.route?.meta?.isCart) {
    reject()
    return router.navigate('/shop/')
  }

  let redirectQuery = userKolCode ? { ref: userKolCode, key: userPostKey || null } : {}

  if (to?.route?.name === 'splash' && !helpers.isBlank(userProductCode)) {
    reject()
    return router.navigate({ name: 'productViewPage', query: redirectQuery, params: { code: userProductCode } })
  }

  if (to?.route?.name === 'shopPage' && !helpers.isBlank(userProductCode)) {
    reject()
    return router.navigate({ name: 'productViewPage', query: redirectQuery, params: { code: userProductCode } })
  }

  if (to?.route?.name === 'shopPage' && !helpers.isBlank(surveyKey)) {
    reject()
    return router.navigate({ name: 'viewSurvey', query: { key: surveyKey } })
  }

  if (to?.route?.name === 'splash' && !helpers.isBlank(surveyKey)) {
    reject()
    return router.navigate({ name: 'productViewPage', query: { key: surveyKey } })
  }

  if (to?.route?.meta?.navigation?.activeLink) {
    store.dispatch('page/setData', {
      activeLink: to.route.meta.navigation.activeLink,
      activeLinkParent: to?.route?.meta?.navigation?.parentName
    })
  }

  if (to?.route?.meta?.navigation?.tabbarLink) {
    store.dispatch('page/setData', {
      tabbarLink: to.route.meta.navigation.tabbarLink
    })
  }

  if (to?.route?.meta?.isSecured) {
    if (userToken && userToken !== '') {
      store.dispatch('user/fetchData', { force: false })
    } else {
      reject()
      return router.navigate('/login/')
    }
  }

  if (to?.query?.linkCode && to?.query?.linkCode != '') {
    const linkCode = to.query.linkCode
    store.dispatch('view/setData', { linkCode })
  }

  const userInfo = store.getters['user/getData']

  if (!to?.route?.meta?.isRegister) {
    if (userToken !== '' && userInfo && userInfo.Wechat && !userInfo.Wechat.IsCompleted) {
      reject()
      return router.navigate('/auth/wechat/register/')
    }
  } else {
    if (userToken !== '' && userInfo && userInfo.Wechat && userInfo.Wechat.IsCompleted) {
      reject()
      return router.navigate('/shop/')
    }
  }

  if (to?.route?.meta?.isComplete && userToken !== '' && userInfo && userInfo && userInfo.IsCompleted) {
    reject()
    return router.navigate('/shop/')
  }

  // resolve()
  // return true

  //if the domain will be reverted to pjfwines.com then please dont forget to remove the redirect..

  if (to.path.indexOf('/home') > -1 ||  to.path.indexOf('/shop') > -1 || to.path.indexOf('/order') > -1 || to.path.indexOf('/address') > -1 || to.path.indexOf('/chat') > -1 && to.path.indexOf('/profile') > -1) {
    return redirectDomain(resolve, to, shopDomain)
  } else {
    return redirectDomain(resolve, to, originDomain, true)
  }
}

const checkIsAlreadyLogin = ({ to, from, resolve, reject, router }) => {
  const userToken = store.getters['user/getToken']

  if (userToken !== '') {
    reject()
    return router.navigate('/')
  }

  resolve()
  return true
}

export { checkMeta, checkIsAlreadyLogin }
