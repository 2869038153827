import axios from 'axios'
import { configs } from '@/utils/configs'
import { helpers } from '@/utils/helpers'

import { useStore } from '@/store'
const store = useStore()

const HTTP = axios.create({
  baseURL: configs.baseURL,
  timeout: configs.axiosTimeout
})

HTTP.interceptors.request.use((config) => {
  const userToken = store.getters['user/getToken']

  if (userToken && userToken !== '') {
    config.headers.Authorization = `Bearer ${userToken}`
  }

  return config
})

const get = async (apiLink, query, isReturnData = false) => {
  try {
    store.dispatch('page/setData', { isFetching: true })
    let res = await HTTP.get(`${apiLink}${query && !helpers.isBlank(query) ? helpers.objToQueryString(query || {}) : ''}`);
    
    if (res && res.status === 200 && (res?.data?.data || res?.data)) {
      helpers.hideLoader()
      store.dispatch('page/setData', { isFetching: false })
      return res.data.data || res?.data
    }
  } catch (err) {
    helpers.hideLoader()
    helpers.catchError(err, true)
    if (isReturnData) {
      return err?.response?.data?.data || {}
    }
  }
}

const post = async (apiLink, post) => {
  try {
    helpers.showLoader()
    let res = await HTTP.post(apiLink, post)

    if (res && res.status === 200 && res?.data?.data) {
      helpers.hideLoader()
      return res.data.data
    }
  } catch (err) {
    helpers.hideLoader()
    helpers.catchError(err, true)
  }
}

const install = (app) => {
  app.config.globalProperties.$HTTP = HTTP
}

export { install as default, HTTP as $HTTP, get, post }
